const check = () => {
  const name = 'cookie_prevent='
  const t = document.cookie.split(';')
  let checkCookie = null

  for (let i = 0; i < t.length; i++) {
    const c = t[i].trim() // Remplace le while avec trim()
    if (c.indexOf(name) === 0) {
      checkCookie = c.substring(name.length, c.length)
    }
  }

  const cookiePreventElement = document.getElementById('cookie-prevent')
  if (checkCookie === '1') {
    if (cookiePreventElement) {
      cookiePreventElement.remove()
    }
  } else {
    if (cookiePreventElement) {
      cookiePreventElement.classList.remove('d-none')
      cookiePreventElement.style.display = 'block'
      cookiePreventElement.style.opacity = '0'
      cookiePreventElement.style.bottom = '-100px'

      // Animation (similaire à JQuery's animate)
      setTimeout(() => {
        cookiePreventElement.style.transition = 'opacity 0.5s, bottom 0.5s'
        cookiePreventElement.style.opacity = '1'
        cookiePreventElement.style.bottom = '0px'
      }, 0)
    }
  }
  return checkCookie
}

const accept = () => {
  document.cookie =
    'cookie_prevent=1; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
  const cookiePreventElement = document.getElementById('cookie-prevent')
  if (cookiePreventElement) {
    cookiePreventElement.style.transition = 'opacity 0.5s, bottom 0.5s'
    cookiePreventElement.style.opacity = '0'
    cookiePreventElement.style.bottom = '-100px'

    setTimeout(() => {
      cookiePreventElement.remove()
    }, 500) // Correspond au temps de l'animation
  }
}

const reject = () => {
  document.cookie =
    'cookie_prevent=0; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
  location.reload()
}

export function cookie () {
  const checkResult = check()

  if (checkResult !== '1') {
    const acceptButton = document.getElementById('cookie-prevent-accept')
    if (acceptButton) {
      acceptButton.addEventListener('click', () => {
        accept()
      })
    }
  }

  const deactivateButton = document.getElementById('deactivate-cookie')
  if (deactivateButton) {
    deactivateButton.addEventListener('click', () => {
      reject()
    })
  }
}
