/**
 * Gestion menu navbar + header
 */
export function handleNav() {
  // il faut init le menu avec d-none pour qu'il soit invisible au chargement de la page
  // mainNavServiceMenu = document.getElementById('mainNavServiceMenu')
  // puis retirer le d-none pour avoir les animations d'hover du menu
  // mainNavServiceMenu.classList.remove('d-none')

  const overlay = document.querySelector('.navbar-overlay')
  // navbar toggler

  const toggleNav = function () {
    const nav = document.querySelector(this.getAttribute('data-trigger'))
    nav.classList.toggle('show')
    if (overlay != null) {
      overlay.classList.toggle('visible')
    }
    document.body.classList.add('open')
    header.classList.remove('blurred')
  }
  document.querySelectorAll('[data-trigger]').forEach((el) => {
    el.addEventListener('click', toggleNav)
  })

  const closeNav = function () {
    document.querySelectorAll('.navbar-collapse').forEach((el) => {
      el.classList.remove('show')
    })
    if (overlay != null) {
      overlay.classList.remove('visible')
    }
    document.body.classList.remove('open')
    header.classList.add('blurred')
  }
  document.querySelectorAll('.navbar-close').forEach((el) => {
    el.addEventListener('click', closeNav)
  })
  if (overlay != null) {
    overlay.addEventListener('click', closeNav)
  }
}

/**
 * Sticky header
 */
export function sticky() {
  const th = document.getElementById('topheader')
  const h = document.getElementById('header')
  const s = 'sticky'
  const b = document.body
  const w = document.querySelector('html')
  const bp = 1199 - 40
  //console.log('window.scrollY', window.scrollY)
  //console.log('document.body', document.querySelector('html').scrollTop)
  //console.log('h.offsetTop', h.offsetTop)
  if (
    (w.scrollTop > 80 && w.offsetWidth > bp) ||
    (w.scrollTop > 80 && w.offsetWidth <= bp)
  ) {
    h.classList.add(s)
    if (th != null) {
      th.classList.add(s)
    }
  } else {
    h.classList.remove(s)
    if (th != null) {
      th.classList.remove(s)
    }
  }
}
